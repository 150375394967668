<template>
  <pro-menu-layout>
    <div class="q-pa-md">
      <pro-deck
        :title="getRes('System.Module.Doi')"
        :cards="userRoleList"
        hide-count-badge
        v-slot="{ card: userRole }"
      >
        <pro-deck-card :title="getRes(userRole.displayName)" expandable>
          <div v-if="userRole.userList.length === 0" class="text-center">
            {{ getRes("Document.AssignedUser.NoUser") }}
          </div>
          <div v-else class="q-gutter-y-sm">
            <div
              v-for="user in userRole.userList"
              :key="user.hcmUserId || user.noLoginUserId"
              class="row items-center q-col-gutter-x-md"
            >
              <div class="col-auto">
                <div class="text-h5">{{ user.declarerName }}</div>
              </div>
              <div v-if="user.status" class="col-auto">
                <q-badge outline :color="user.status.color"
                  >{{ user.status.label }}
                </q-badge>
              </div>
              <q-space />
              <div v-if="user.dateSubmitted" class="col-auto">
                {{ $t("Form.Field.LastSubmission") }}
                {{
                  $proSmart.common.getFormattedDate(
                    new Date(user.dateSubmitted)
                  )
                }}
              </div>
              <div class="col-auto q-gutter-x-sm">
                <q-btn
                  no-caps
                  v-if="user.approvalRoute"
                  padding="xs md"
                  outline
                  color="primary"
                  :label="getRes('System.Button.SubmitForApproval')"
                  :to="user.approvalRoute"
                />
                <q-btn
                  no-caps
                  v-if="user.dateSubmitted"
                  padding="xs md"
                  outline
                  color="primary"
                  :label="getRes('System.Button.View')"
                  @click="viewDoi(user)"
                />
                <q-btn
                  no-caps
                  v-if="user.uploadRoute"
                  padding="xs md"
                  outline
                  color="primary"
                  :label="getRes('System.Button.Upload')"
                  :to="user.uploadRoute"
                />
              </div>
            </div>
          </div>
        </pro-deck-card>
      </pro-deck>
      <audit-trail-record-dialog ref="audit" />
    </div>
  </pro-menu-layout>
</template>

<script>
import ProMenuLayout from "@/components/PROSmart/Layout/ProMenuLayout";
import ProDeck from "@/components/PROSmart/Deck/ProDeck";
import ProDeckCard from "@/components/PROSmart/Deck/ProDeckCard";
import AuditTrailRecordDialog from "@/components/PROSmart/AuditTrailRecordDialog";

export default {
  components: { ProMenuLayout, ProDeck, ProDeckCard, AuditTrailRecordDialog },
  props: {
    documentStatus: Number,
  },
  data() {
    return {
      userRoleList: [],
      allowDoiStatus: [50, 80],
    };
  },
  methods: {
    getViewUserFormRoute(user, workflowCode, userRoleName) {
      return {
        name: "DocumentViewUserForm",
        params: {
          mode: "Edit",
          code: workflowCode,
          userId: user.declarerHcmId,
          doiUserRole: userRoleName,
        },
      };
    },
    getViewNoLoginUserFormRoute(user, workflowCode) {
      return {
        name: "DocumentViewNoLoginUserForm",
        params: {
          mode: "Edit",
          code: workflowCode,
          noLoginUserId: user.declarerNoLoginUserId,
        },
      };
    },
    getStatus(user) {
      if (user.conflictOfInterest === null) return null;

      if (user.conflictOfInterest === false || user.approved === true)
        return {
          color: "primary",
          label: this.getRes("System.Module.Doi.NoCoi"),
        };

      if (user.underApproval === true)
        return {
          color: "orange",
          label: this.getRes("System.Module.Doi.UnderApproval"),
        };

      return {
        color: "red",
        label: this.getRes("System.Module.Doi.Coi"),
      };
    },
    isAllowSubmitForApproval(user) {
      return (
        user.conflictOfInterest === true &&
        user.underApproval !== true &&
        user.approved === null &&
        this.allowDoiStatus.includes(this.documentStatus)
      );
    },
    isAllowUpload() {
      return this.allowDoiStatus.includes(this.documentStatus);
    },
    viewDoi(user) {
      this.$refs.audit.showInfo(user.processId, this.$route.params.id, true);
    },
  },
  created() {
    Promise.all([
      this.$proSmart.codeTable.getTenderUserRole(this),
      this.$proSmart.tender.getDOIResponseList(this, this.$route.params.id),
      this.$proSmart.tender.getWorkflowCode(this, this.$route.params.id, [
        "doi_approval",
        "doi_approval_no_login",
        "upload_signed_doi",
        "upload_signed_doi_no_login",
      ]),
    ]).then(
      ([
        userRoleList,
        doiResponseList,
        [doiApproval, doiApprovalNoLogin, uploadDoi, uploadDoiNoLogin],
      ]) => {
        this.userRoleList = userRoleList.reduce((arr, userRole) => {
          let internalUserList = doiResponseList
            .filter(
              (user) =>
                user.roleName === userRole.keyName && !user.isExternalAssessor
            )
            .map((user) => ({
              ...user,
              status: this.getStatus(user),
              approvalRoute:
                doiApproval.visible && this.isAllowSubmitForApproval(user)
                  ? this.getViewUserFormRoute(
                      user,
                      doiApproval.workflowCode,
                      userRole.displayName
                    )
                  : null,
              uploadRoute:
                uploadDoi.visible && this.isAllowUpload()
                  ? this.getViewUserFormRoute(
                      user,
                      uploadDoi.workflowCode,
                      userRole.displayName
                    )
                  : null,
            }));

          if (internalUserList.length > 0) {
            arr.push({
              ...userRole,
              key: `i_${userRole.id}`,
              userList: internalUserList,
            });
          }

          let externalUserList = doiResponseList
            .filter(
              (user) =>
                user.roleName === userRole.keyName && user.isExternalAssessor
            )
            .map((user) => ({
              ...user,
              status: this.getStatus(user),
              approvalRoute:
                doiApprovalNoLogin.visible &&
                this.isAllowSubmitForApproval(user)
                  ? this.getViewNoLoginUserFormRoute(
                      user,
                      doiApprovalNoLogin.workflowCode
                    )
                  : null,
              uploadRoute:
                uploadDoiNoLogin.visible && this.isAllowUpload()
                  ? this.getViewNoLoginUserFormRoute(
                      user,
                      uploadDoiNoLogin.workflowCode
                    )
                  : null,
            }));

          if (externalUserList.length > 0) {
            arr.push({
              ...userRole,
              displayName: `${userRole.displayName}Ext`, //TODO: i18n for external assessor
              key: `e_${userRole.id}`,
              userList: externalUserList,
            });
          }

          return arr;
        }, []);
      }
    );
  },
};
</script>
